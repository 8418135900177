// Glider Configuration
new Glider(document.querySelector(".carousel-institucional-glider"), {
  slidesToShow: 1,
  slidesToScroll: 1,
  scrollLock: true,
  draggable: true,
  duration: 2,
  arrows: {
    prev: "#glider-prev",
    next: "#glider-next"
  },
  dots: ".carousel-institucional-dots",
})

function translateAriaCarouselHeader() {
  document.querySelector('.carousel-institucional #glider-prev')
    .setAttribute('aria-label', 'Anterior')
  document.querySelector('.carousel-institucional #glider-next')
    .setAttribute('aria-label', 'Próxima')

  document.querySelectorAll('.carousel-institucional-dots button.glider-dot').forEach(button => {
    button.setAttribute('aria-label', `Página ${button.getAttribute('data-index')}`)
  })
}

translateAriaCarouselHeader()